/* src/components/LoadingSpinner.css */
.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh; /* Full viewport height */
  }
  
  .spinner {
    border: 6px solid #f3f3f3; /* Light gray */
    border-top: 6px solid #544cfb; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }